import React from 'react';
import FormOne from '../contact/FormOne';


const AboutOne = () => {
    return (
        <section id="about" className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">About Us</span>
                                <h2 className="title mb--40">We take your projects personally</h2>
                                <p>Founded on the belief that existing agencies often fell short in delivering the caliber of work we knew was possible, Concept Catalyst takes immense pride in setting a new standard for excellence.</p> 
                                <p>Comprised of a team of seasoned entrepreneurs, designers, and software engineers, we're dedicated to building software of the highest quality. We treat every project as if it were our own, driven by a shared passion for excellence and a commitment to pushing boundaries.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">Get a free quote now</h3>
                           <FormOne />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;