import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

const impactStrings = [
    '<span style="color: #cbdf59;">B</span>' +
    '<span style="color: #837fba;">o</span>' +
    '<span style="color: #ffd533;">l</span>' +
    '<span style="color: #cbdf59;">d</span>' +
    '<span style="color: #f37863;">e</span>' +
    '<span style="color: #c7ab83;">s</span>' +
    '<span style="color: #a651a4;">t</span>',

    '<span style="color: #cbdf59;">W</span>' +
    '<span style="color: #837fba;">i</span>' +
    '<span style="color: #ffd533;">l</span>' +
    '<span style="color: #cbdf59;">d</span>' +
    '<span style="color: #f37863;">e</span>' +
    '<span style="color: #c7ab83;">s</span>' +
    '<span style="color: #a651a4;">t</span>',

    '<span style="color: #cbdf59;">A</span>' +
    '<span style="color: #837fba;">m</span>' +
    '<span style="color: #ffd533;">b</span>' +
    '<span style="color: #cbdf59;">i</span>' +
    '<span style="color: #f37863;">t</span>' +
    '<span style="color: #c7ab83;">i</span>' +
    '<span style="color: #a651a4;">o</span>' +
    '<span style="color: #f37863;">u</span>' +
    '<span style="color: #cbdf59;">s</span>'
]


const BannerOne = ({onGetStartedClick}) => {
    return (
        <div className="banner banner-style-1">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            {/* <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}> */}
                                <div style={{ display: 'flex' }}>

                                    <h1 className="title">Ignite Your
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typewriter
                                                options={{
                                                    strings: impactStrings,
                                                    autoStart: true,
                                                    loop: true,
                                                    cursor: "",
                                                    delay: 60
                                                }}
                                            />
                                            &nbsp;Ideas
                                        </div>
                                    </h1>
                                </div>
                                <span className="subtitle">Concept Catalyst helps to take your most ambitious projects from a concept to the next industry-shattering innovation.</span>
                                <button onClick={() => onGetStartedClick()} className="axil-btn btn-fill-primary btn-large">Get Started</button>
                            {/* </AnimationOnScroll> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-thumbnail">
                            {/* <AnimationOnScroll animateIn="fadeInUp" delay={100} animateOnce={true}> */}
                                <div className="large-thumb">
                                    <img style={{height: '60%', width: '60%', marginLeft: '10%'}} src={process.env.PUBLIC_URL + "/images/building_rocket_blue.svg"} />
                                </div>
                            {/* </AnimationOnScroll> */}
                            {/* <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}>
                                <div className="large-thumb-2">
                                    <img src={process.env.PUBLIC_URL + "/images/banner/laptop-poses.png"} alt="Laptop" />
                                </div>
                            </AnimationOnScroll> */}
                            {/* <ul className="list-unstyled shape-group">
                                <li className="shape shape-1">
                                    <AnimationOnScroll animateIn="slideInLeft" duration={1} delay={800} animateOnce={true}>
                                        <img src={process.env.PUBLIC_URL + "/images/banner/chat-group.png"} alt="chat" />
                                    </AnimationOnScroll>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-21">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"} alt="Bubble" />
                </li>
                {/* <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li> */}
                <li className="shape shape-4">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-5">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-6">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                </li>
                <li className="shape shape-7">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerOne;