import React, { useRef } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import BannerOne from '../component/banner/BannerOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';


const DigitalAgency = () => {
    document.querySelector('body').classList.add("active-dark-mode");

    const aboutRef = useRef(null);
    const scrollToAbout = () => {
        console.log("Test")
        aboutRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    return (
        <>
        <SEO title="Concept Catalyst"/>
        <main className="main-wrapper">
            <HeaderOne />
            <BannerOne onGetStartedClick={() => scrollToAbout()} />
            <div className="section section-padding-2 bg-color-dark">
                <div className="container">
                    <SectionTitle 
                        subtitle="How We Can Help"
                        title="Expertise throughout the tech stack"
                        description="Concept Catalyst's premier development team allows us to provide solutions at every stage of the software development lifecycle."
                        textAlignment="heading-light-left"
                        textColor=""
                    />
                    <div className='row'>
                        <ServicePropOne colSize="col-xl-4 col-md-6" serviceStyle="" itemShow="6" />
                    </div>
                </div>
                <ul className="list-unstyled shape-group-10">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                </ul>
            </div>
            <div ref={aboutRef} />
            <AboutOne />
        </main>
        </>
    )
}

export default DigitalAgency;

